import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { pdf } from '@react-pdf/renderer';
import Printer from '../Components/Print/Printer';
import { toast } from 'react-toastify';
import DlgScriptEdit from '../Components/Dialogue/DlgScriptEdit';
import DlgScriptExtension from '../Components/Dialogue/DlgScriptExtension';
import DlgScriptCancel from '../Components/Dialogue/DlgScriptCancel';
import { logout } from '../reduxAuth/authSlice';


function ScriptDetails() {
    const {user}                                              = useSelector((state) => state.auth);
    const [processing, setProcessing]                         = useState(false);
    const [thisScript, setThisScript]                         = useState();
    const [downloadLogs, setDownLoadLogs]                     = useState([]);
    const params                                              = useParams();

    const [showScriptClientEdit, setShowScriptClientEdit]                    = useState(false);
    const [showScriptClientExtension, setShowScriptClientExtension]          = useState(false);
    const [showScriptCancellation, setShowScriptCancellation]                = useState(false);
    
    const [relatedScriptsList, setRelatedScriptsLit]                         = useState([]);
    const [prevOriginalScriptSRC, setPrevOriginalScriptSRC]                  = useState("");

    const [showChatRequest, setShowChatRequest]                              = useState(false);

    //const pdfRef                                                           = useRef();
    const dispatch                                                           = useDispatch();

    useEffect(()=> {
      getThisScript();
    },[thisScript])
    
    useEffect(() => {
      if(prevOriginalScriptSRC.length > 1){
        scrollToTheBottom();       
      }
    },[prevOriginalScriptSRC]);

    const getThisScript = async () => {

      try {
        
          const res = await axios.get(CONSTANTS.API_URL +"scripts/details/"+ params.id, {
               headers: {
                   token: "Bearer "+ user.accessToken
               }
           });
 
          //console.log(res.data);
          setThisScript(res.data);
          //DownloadLogs
          if(thisScript){
            getDownloadedLogs();
            collectAllRelatedExtensions();
          }
         

        } catch (err) {
          console.log(err);
           console.log("ERRRRRRR Script");
           //Check if token is invalid     
            if(err.response.status === 403){
              localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
              dispatch(logout());
              toast.error("Session has timeout out.")
              setTimeout(() => {
                document.location.href("/login");
              }, 4000);
            }
        }      
  }

  const getDownloadedLogs = async () => {

    try {
    
        const res = await axios.get(CONSTANTS.API_URL +"scripts/downloaded/user/logs/" + thisScript.scriptcode, {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });

       // console.log(res.data);
        setDownLoadLogs(res.data);
        //DownloadLogs
     
      } catch (err) {
        console.log(err);
        console.log("ERRRRRRR Script");
      }      
  }

  const cancelThisScript = async () => {
    //console.log("Cancel Script");
    setShowScriptCancellation(true);

  }

  const createPrintScript = async () => {
    //console.log("CREATE SCRIPT");
    setProcessing(true);
    //get pharmacy Title
     const pharmacyInfo = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/" + thisScript.pharmacycode  ,{
            headers: {
                token: "Bearer " + user.accessToken
            }
      });

    //const currentTimeStamp = "1745028101";
    const currentTimeStamp = Date.now().toString() + "";
    const pharmacyTitle = pharmacyInfo.data.title;

    thisScript.generatedTimeStamp  = currentTimeStamp;

    //const blob = await Document.toBlob(<Printer dataScriptObject={thisScript}  />);
    const blob = await pdf(<Printer dataScriptObject={thisScript} />).toBlob();
    const randNum = Math.floor(Math.random() * 900) + 100;
    // Assuming generatedFile is a Blob
    const formData = new FormData();
    formData.append("newscriptfile", blob, "RSCR_" + thisScript.scriptcode + "_" + thisScript.versionCode +"_"+randNum+ ".pdf");
    formData.append("scriptcode", thisScript.scriptcode);
    formData.append("idnumber", thisScript.idNumber);
    formData.append("versioncode", thisScript.versionCode);
    formData.append("pharmacy", pharmacyTitle);
    formData.append("fullname", thisScript.fullname);
    formData.append("expirydate", thisScript.expiryDate);
    formData.append("generatedTimeStamp", currentTimeStamp)
    
     try{

      const resScript = await axios.post(CONSTANTS.API_URL +"scripts/form/menuscript/generation", formData ,{
            headers: {
              'Content-Type': 'multipart/form-data',
                token: "Bearer " + user.accessToken
            }
        });

        //console.log(resScript.data);
        toast.success("Script has been generated. Ready for download");
        setProcessing(false);
     }catch(err){
      setProcessing(false);
      toast.error("Something has gone wrong.");
      console.log(err);
     }
  }

  const editPrintScript  = async () => {
    setShowScriptClientEdit(true);
  }

  const downloadTheScript = async () => {

    try{

      const downData = {
        "scriptcode": thisScript.scriptcode,
        "key": thisScript.aws.key,
        "userid": user._id,
        "loadfile": "application/pdf"
      }

      const resScriptDown = await axios.put(CONSTANTS.API_URL +"scripts/downloading/signed/file", downData ,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        if(resScriptDown.status === 203){
          toast.warning(resScriptDown.data.message);
          setShowChatRequest(true);
        }else {          
          //toast.success("Script has been generated. Ready for download");
          window.open(resScriptDown.data.url, '_blank', 'noreferrer');
          window.location.replace("/scripts");
        }
       
        setProcessing(false);
     }catch(err){
      setProcessing(false);
      toast.error("Something has gone wrong.");
      console.log(err);
     }
  }

  /*
  const extendThisScript = async () => {
    try{
      //console.log("Current Script");
      //console.log(thisScript);
      setShowScriptClientExtension(true);
    }catch(err){
      setProcessing(false);
      toast.error("Something has gone wrong.");
      console.log(err);
     }
  }
  */

  const collectAllRelatedExtensions = async () => {
        
    try {
       
         if(parseInt(thisScript.version) > 1){

            const related = {
                "scriptcode": thisScript.scriptcode,
                "versioncode" : thisScript.versionCode
            }

            const res = await axios.put(CONSTANTS.API_URL +"scripts/extended/collection/" , related, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            
            //console.log(res.data);
            setRelatedScriptsLit(res.data)
         }
     
      } catch (err) {
        console.log(err);
        console.log("ERRRRRRR Script");      
      }      
  }

  const functionToReturnFeedback = (createdAt) => {
    var returnString = "";
    var initialDate = new Date(createdAt).toLocaleDateString()

    const now = new Date();
    const diff = now - new Date(createdAt);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      returnString = `${seconds} seconds ago`;
    } else if (minutes === 1) {
      returnString = `1 minute ago`;
    } else if (minutes < 60) {
      returnString = `${minutes} minutes ago`;
    } else if (hours === 1) {
      returnString = `1 hour ago`;
    } else if (hours < 24) {
      returnString = `${hours} hours ago`;
    } else if (days === 1) {
      returnString = `Yesterday`;
    } else if (days < 7) {
      returnString = `${days} days ago`;
    } else if (weeks === 1) {
      returnString = `1 week ago`;
    } else if (weeks < 4) {
      returnString = `${weeks} weeks ago`;
    } else if (months === 1) {
      returnString = `1 month ago`;
    } else if (months < 12) {
      returnString = `${months} months ago`;
    } else if (years === 1) {
      returnString = `1 year ago`;
    } else {
      returnString = `${years} years ago`;
    }

    return initialDate + " - " +returnString;
  }

  //check original script url type
  function isImage(url) {
    const extensions = ['jpg', 'jpeg', 'png', 'gif'];
    const extension = url.split('.').pop().toLowerCase();
    return extensions.includes(extension);
  }
  
  function isPdf(url) {
    const extension = url.split('.').pop().toLowerCase();
    return extension === 'pdf';
  }
  
  function isWordDoc(url) {
    const extensions = ['docx', 'docm']; // Add more Word doc extensions if needed
    const extension = url.split('.').pop().toLowerCase();
    return extensions.includes(extension);
  }

  //Call the aws signed url
  const callSignedUrlForDisplay = async (targetOption, fileType) => {
    try{
      setProcessing(true);
     
      var fileString = "";

      if(fileType === "word"){
        fileString = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }

      if(fileType === "pdf"){
        fileString = "application/pdf";
      }

      const keyAssign = {
        "key": thisScript.origAws.key,
        "fileContent" : fileString
      }
    
      const resKey = await axios.put(CONSTANTS.API_URL +"scripts/view/signed/link" , keyAssign, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

     if(targetOption === "Doc"){
         // console.log(resKey.data.url);
         // console.log("Raaaaaa");
            const a = document.createElement('a');
            a.href = resKey.data.url;
            a.target = '_blank';
            a.click();

         }else {          
             setPrevOriginalScriptSRC(resKey.data.url);          
      }
      setProcessing(false);
    }catch(err){
      setProcessing(false);
      toast.error("Couldn't get authenticated link, please trya again later.")
    }
  }

  const scrollToTheBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  }
  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Script Test</h2>
        
        <div className="card">
            <div className="card-body">
                 <DlgScriptEdit 
                        setShowScriptClientEdit={setShowScriptClientEdit} 
                        showScriptClientEdit={showScriptClientEdit} 
                        thisScript={thisScript}
                        person={user} 
                    />
                    
                <DlgScriptCancel 
                    showScriptCancellation={showScriptCancellation}
                    setShowScriptCancellation={setShowScriptCancellation}
                    thisScript={thisScript}
                    person={user} 
                    />
                  
                <DlgScriptExtension 
                    showScriptClientExtension={showScriptClientExtension}
                    setShowScriptClientExtension={setShowScriptClientExtension}
                    thisScript={thisScript}
                    person={user} 
                    />
                {
                  thisScript && (
                    <div className="single-details">
                      <table className="tbl-nostyle-full tbrspaces tbcellthird">
                        <tbody>
                          <tr>
                            <td>
                               <div className="sm-label">Full Name:</div> {thisScript.fullname}
                            </td>
                            <td>
                            <div className="sm-label">Scritpt Code:</div> <strong>{thisScript.scriptcode}</strong>
                            </td>
                            <td>
                            <div className="sm-label">Version:</div> {thisScript.versionCode} / {thisScript.version} 
                            </td>
                          </tr>
                          <tr>
                              <td>
                                <div className="sm-label">Medical Aid:</div> {thisScript.medical.medicalAidOption == true ? "Yes" : "No"}
                              </td>
                              <td>
                                  <div className="sm-label">Medical Aid Name:</div> {thisScript.medical.medicalAidName}
                              </td>
                              <td>
                                  <div className="sm-label">Medical Aid Number:</div> {thisScript.medical.medicalAidNumber} 
                              </td>
                          </tr>
                          <tr>
                          <td>
                                <div className="sm-label">Status:</div> {thisScript.status.text}
                            </td>
                            <td>
                               <div className="sm-label">Downloads Count:</div> {thisScript.status.printCount}
                            </td>
                            <td>
                                <div className="sm-label">Script Confirmation:</div> { thisScript.status.confirmation === false ? "Unconfirmed": "Confirmed"}
                            </td>                            
                          </tr>
                          <tr>
                            <td>
                                <div className="sm-label">Valid Until:</div> 
                                {
                                  new Date(thisScript.expiryDate).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })
                                }
                            </td>                            
                            <td>
                               <div className="sm-label">Date Created:</div> 
                                {
                                  new Date(thisScript.createdAt).toLocaleDateString(undefined, {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })
                                }
                            </td>
                            <td>
                              <div className="sm-label">ID NUMBER:</div>
                                 {thisScript.idNumber}
                            </td>
                          </tr>
                          <tr>
                              <td> 
                                <div className="sm-label">Medication:</div> 
                                {
                                          thisScript.prescriptions && (
                                            <ul>
                                             {
                                                 thisScript.prescriptions.map((thePres, i) => {
                                                    return (<li className="mp-prescript-item" key={i}>     
                                                              {thePres}
                                                      </li>
                                                      )
                                                  })
                                             }
                                            </ul>
                                          )
                                      }
                              </td>
                              <td className="vatop">
                                 <div className="sm-label">Payment:</div> 
                                 {
                                    (thisScript.payment!== null || thisScript.payment !== undefined) && 
                                          thisScript.payment.payType === "Levi" ?
                                        <div className="pay">Levi - Paid in the pharmacy.</div>
                                        :
                                        <div className="pay">Claimed from the medical Aid.</div>
                                 }
                              </td>
                             <td className="script-old-view">
                              <h4 className="small-scriptor">Old Script Details</h4>
                              <div className="pd10">
                                <table className="table strip-scnum">
                                    <tbody>
                                      <tr>
                                        <td>
                                          Script Number
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {thisScript.origScriptNumber}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Script Number Dispensary
                                        </td>
                                        <td>:</td>
                                        <td>
                                          {thisScript.origScriptNumDispensed}
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                               {
                                thisScript.origCopy && (
                                  <>
                                    {isImage(thisScript.origCopy) && 
                                      <div className="copy-display">
                                        <div className="copy-display">
                                          <p>You may be able to view the original script from this.</p>
                                          <button onClick={() => callSignedUrlForDisplay("Picture", "img")} className="btn btn-rescure">View Original Script</button>
                                        </div>
                                        {
                                          prevOriginalScriptSRC && (
                                            <img src={prevOriginalScriptSRC} alt="Original Script" className="image-area-original" />
                                          )
                                        }
                                        
                                      </div>
                                    }
                                    {isPdf(thisScript.origCopy) && 
                                      <div className="copy-display">
                                        <p>You may be able to view the original script from this.</p>
                                        <button onClick={() => callSignedUrlForDisplay("Doc", "pdf")} className="btn btn-rescure">View Original Script</button>
                                      </div>
                                    }

                                    {isWordDoc(thisScript.origCopy) && 
                                        <div className="copy-display">
                                          <p>You may be able to view the original script from this.</p>
                                          <button onClick={() => callSignedUrlForDisplay("Doc", "word")} className="btn btn-rescure">View Original Script</button>
                                        </div>  
                                    }
                                  </>
                                )
                               }
                             </td>
                   
                          </tr>
                        </tbody>
                      </table>
                      {
                        thisScript.visible && (
                          <div className="foot-display">
                            <table className="tbl-nostyle-full tbrspaces tbcellthird">
                              <tbody>
                                  {
                                      (thisScript.downloadable === true)
                                        && (
                                          <tr>
                                                <td>
                                                    <Button
                                                      label="Download Script"
                                                      className="btn btn-rescure"
                                                      onClick={() => downloadTheScript()}
                                                    />
                                                </td>                                             
                                                <td>                                                  
                                                </td>
                                            </tr>
                                        )
                                      }
                                  <tr>
                                  <td className="link-small-styles">
                                         <Button
                                            label="Cancel Script "
                                            className="link-line"
                                            onClick={() => cancelThisScript()}
                                            />
                                          <span className="bar-extra">|</span>
                                          <Button
                                                label="Edit Script "
                                                className="link-line"
                                                onClick={() => editPrintScript()}
                                              />
                                          {
                                             (thisScript.downloadable === true)
                                             && (
                                              <>
                                               <span className="bar-extra">|</span>
                                                  <Link to={"/patient/" + thisScript.idNumber} className="link-line">Patient Scripts Info</Link>
                                              </>
                                             )
                                          }
                                         
                                    </td>                                  
                                    <td className="txtright">
                                    {
                                      (thisScript.downloadable === false) 
                                          && (
                                              <Button
                                                label="Generate Script"
                                                className="btn btn-rescure"
                                                onClick={() => createPrintScript()}
                                              />
                                            )
                                      }
                                        
                                    </td>   
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      }
                      {
                          showChatRequest && (
                            <div className="foot-display">
                                <p>In order to get approval please request.</p>
                                <Link to={"/chat/" + thisScript._id} className="btn btn-rescure" target="_blank">Request Approval</Link>
                            </div>  
                          )
                        }
                        <div className="foot-display">
                          <div className="foot-item">
                              <div className="iti-title">Download Log</div>
                                <div className="dn-logs">
                                    {
                                        downloadLogs && (
                                          <table className="table list-logs v-table">
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Email</th>
                                                <th>Script Code</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {
                                              //downloadLogs
                                              downloadLogs.map((downs, keyIndex) => {
                                                return ( <tr className="log-phase" key={keyIndex}>
                                                      <td className="dnFlexItem1">{functionToReturnFeedback(downs.createdAt)}</td>
                                                      <td className="dnFlexItem1">{downs.name}</td>
                                                      <td className="dnFlexItem1">{downs.surname}</td>
                                                      <td className="dnFlexItem1">{downs.email}</td>
                                                      <td className="dnFlexItem1">{downs.scriptcode}</td>
                                                </tr>)
                                              })
                                            }
                                            </tbody>
                                          </table>
                                        )
                                    }
                                </div>
                            </div>
                          {
                              (parseInt(thisScript.version) > 1) && (
                                  <div className="foot-item">
                                      <div className="iti-title">Script extension history.</div>
                                      <table className="table table-bordered">
                                          <thead>
                                              <tr>
                                                  <th>Full Name</th>
                                                  <th>Script Code</th>
                                                  <th>Version Code</th>
                                                  <th>Expiry Date</th>
                                                  <th>Prints</th>
                                                  <th>View</th>
                                              </tr>
                                          </thead>
                                          <tbody>

                                              {
                                                  relatedScriptsList.map((relatedItem, index) => {
                                                      return (<tr key={index}>
                                                          <td>{relatedItem.fullname}</td>
                                                          <td>{relatedItem.scriptcode}</td>
                                                          <td>{relatedItem.versionCode} / {relatedItem.version}</td>
                                                          <td>{new Date(relatedItem.expiryDate).toLocaleDateString(undefined, {
                                                              year: 'numeric',
                                                              month: 'long',
                                                              day: 'numeric',
                                                              })}
                                                          </td>
                                                          <td>{relatedItem.status.printCount}</td>
                                                          <td><Link to={"/menuscript/" + relatedItem._id} className="vs-link">View</Link></td>
                                                      </tr>)
                                                  })
                                              }
                                          </tbody>
                                      </table>
                                  </div>
                                )
                            }
                       </div>
                    </div>
                  )
                }
            </div>
            
            {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                    </div>
                )
           }
        </div>
    </div>
  )
}

export default ScriptDetails